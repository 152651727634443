// ImageCrop Component: Responsible for cropping images using the React Advanced Cropper library.
// Handles image cropping, aspect ratio settings, and coordinating with the Cropper library.
// This component is utilized by two components: ImageSelector and CropModalPanel.

import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Cropper } from 'react-advanced-cropper';

import { CropFormat } from '~modules/media-library/model/CropFormat';
import { fetchImage } from '~services/api/calls/image';
import 'react-advanced-cropper/dist/style.css';
type ImageCropProps = {
  aspect?: any;
  cropFormat?: CropFormat;
  imgSrc: string;
  setCroppedArea: (croppedArea: PixelCrop) => void;
  disabled?: boolean;
  cropperRef: any;
  className?: string;
  defaultCoordinates?: { top: number; left: number; width: number; height: number };
};

export const ImageCrop = ({
  imgSrc,
  setCroppedArea,
  cropFormat,
  cropperRef,
  aspect,
  className,
  defaultCoordinates,
}: ImageCropProps) => {
  const ref = useRef(null);
  const [base64Image, setBase64Image] = useState<any>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = (await fetchImage(imgSrc)) as any;

        const uint8Array = new Uint8Array(response);
        const binaryString = uint8Array.reduce((data, byte) => {
          return data + String.fromCharCode(byte);
        }, '');

        const base64String = `data:image/png;base64,${btoa(binaryString)}`;
        setBase64Image(base64String);
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    };

    const timeout = setTimeout(() => {
      fetchData();
    }, 500);

    return () => clearTimeout(timeout);
  }, [imgSrc]);

  const changeCroppedArea = useCallback(
    cropper => {
      const image = document.querySelector('.advanced-cropper-background-image') as HTMLImageElement;

      if (image) {
        const { width, height, top, left } = cropper.getCoordinates();

        const coordinatesInPixel: PixelCrop = {
          unit: 'px',
          y: top,
          x: left,
          width,
          height,
        };

        setCroppedArea(coordinatesInPixel);
      }
    },
    [setCroppedArea],
  );

  useEffect(() => {
    changeCroppedArea(cropperRef.current);
  }, [cropFormat, cropperRef, changeCroppedArea]);

  const onChange = useCallback(
    cropper => {
      changeCroppedArea(cropper);
    },
    [changeCroppedArea],
  );

  useEffect(() => {
    if (cropperRef.current) {
      cropperRef.current.refresh();
    }
  }, [cropFormat, cropperRef]);

  function calculateAspectRatio(width, height) {
    if (width === null || height === null || width === 0 || height === 0) {
      return undefined;
    }
    const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
    const divisor = gcd(width, height);
    const aspectRatioWidth = width / divisor;
    const aspectRatioHeight = height / divisor;
    return aspectRatioWidth / aspectRatioHeight;
  }

  const aspectRatio = cropFormat
    ? calculateAspectRatio(cropFormat.width, cropFormat.height)
    : aspect
    ? aspect
    : undefined;

  const BASE_CROPPER_SIZE = 800;

  const getCropperDimensions = (aspectRatio: number) => {
    if (aspectRatio >= 1) {
      return {
        width: BASE_CROPPER_SIZE,
        height: BASE_CROPPER_SIZE / aspectRatio,
      };
    }
    return {
      width: BASE_CROPPER_SIZE * aspectRatio,
      height: BASE_CROPPER_SIZE,
    };
  };

  useEffect(() => {
    if (cropperRef.current && cropFormat && cropFormat.width && cropFormat.height) {
      const aspectRatio = calculateAspectRatio(cropFormat.width, cropFormat.height);
      const cropperDimensions = getCropperDimensions(aspectRatio);
      // Get actual image dimensions
      const image = cropperRef.current.getImage();
      const imageWidth = image.width;
      const imageHeight = image.height;

      // Scale cropper dimensions to fit within image
      const scale = Math.min(imageWidth / cropperDimensions.width, imageHeight / cropperDimensions.height) * 0.8;
      const scaledDimensions = {
        width: cropperDimensions.width * scale,
        height: cropperDimensions.height * scale,
      };

      cropperRef.current.setCoordinates({
        ...scaledDimensions,

        left: (imageWidth - scaledDimensions.width) / 2,
        top: (imageHeight - scaledDimensions.height) / 2,
      });
    }
  }, [cropFormat, cropperRef]);
  return (
    <div ref={ref} className={clsx(className, 'cropper-wrapper')}>
      {base64Image && (
        <Cropper
          crossOrigin="use-credentials"
          defaultCoordinates={defaultCoordinates && defaultCoordinates.height ? defaultCoordinates : undefined}
          ref={cropperRef}
          stencilProps={{
            aspectRatio: aspectRatio,
            movable: true,
            resizable: true,
          }}
          src={base64Image}
          onChange={onChange}
        />
      )}
    </div>
  );
};
